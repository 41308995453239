import Image from "next/image";

import axios from "axios";
import type { GetStaticProps, NextPage } from "next";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Body from "../components/Body/Body";
import Button from "../components/Button/Button";
import Caption from "../components/Caption/Caption";
import Card from "../components/Card/Card";
import Detail from "../components/Detail/Detail";
import Grid from "../components/Grid/Grid";
import HeroSection from "../components/HeroSection/HeroSection";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import Page from "../components/Page/Page";
import Picture from "../components/Picture/Picture";
import Section from "../components/Section/Section";
import Title from "../components/Title/Title";
import IndexImage from "../public/images/index.jpg";
import { ProductData, ProductResponse } from "../types";

interface ProductPageProps {
	products: ProductData[];
}

const Index: NextPage<ProductPageProps> = ({ products }) => {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	// Testing build
	return (
		<Page
			title="An Insurance Company You Can Rely On | Global Insurance Agency"
			description="Get an insurance quote in minutes from a top-rated company. Find 24/7 support and insurance for you, your family, and your belongings."
		>
			{(isSmall || isMedium) && (
				<HeroSection theme="white" ruler={false}>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="small"
						largeRowGap="small"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<HeroTitle
							first="Compare."
							second="Choose."
							third="Save."
						/>
						<Body>
							Find your perfect policy in minutes, and get better
							coverage at better prices. Compare prices from 30+
							of the best insurance carriers to find your perfect
							match and price.
						</Body>
						<Button
							internal={false}
							href="https://nwexpress.com/giausa/multi-quote/getting-started"
						>
							Get a Quote
						</Button>
					</Grid>
				</HeroSection>
			)}
			{isLarge && (
				<HeroSection theme="white" ruler={false}>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={2}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="small"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="small"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={2}
							largeColumnGap="small"
							largeRowGap="small"
							largeWidth={75}
							justify="center"
							align="center"
						>
							<HeroTitle
								first="Compare."
								second="Choose."
								third="Save."
							/>
							<Body>
								Find your perfect policy in minutes, and get
								better coverage at better prices. Compare prices
								from 30+ of the best insurance carriers to find
								your perfect match and price.
							</Body>
						</Grid>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="small"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="small"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={2}
							largeRowNumber={6}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							{products.map((product) => (
								<Card
									key={product.attributes.slug}
									text={product.attributes.hero_title}
									href={`/products/${product.attributes.slug}`}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style={{
											width: "100%",
											height: "100%",
										}}
									>
										<g clipPath="url(#clip0_1_2)">
											<path
												d={
													product.attributes
														.icon_svg_d
												}
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</g>
									</svg>
								</Card>
							))}

							<Card
								text="More Choices"
								href="https://rater.gia-usa.com/contact"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<path
										d="M7 12.5C7.13261 12.5 7.25979 12.4473 7.35355 12.3536C7.44732 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5C6.86739 11.5 6.74021 11.5527 6.64645 11.6464C6.55268 11.7402 6.5 11.8674 6.5 12C6.5 12.1326 6.55268 12.2598 6.64645 12.3536C6.74021 12.4473 6.86739 12.5 7 12.5V12.5ZM12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12C12.5 11.8674 12.4473 11.7402 12.3536 11.6464C12.2598 11.5527 12.1326 11.5 12 11.5C11.8674 11.5 11.7402 11.5527 11.6464 11.6464C11.5527 11.7402 11.5 11.8674 11.5 12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5ZM17 12.5C17.1326 12.5 17.2598 12.4473 17.3536 12.3536C17.4473 12.2598 17.5 12.1326 17.5 12C17.5 11.8674 17.4473 11.7402 17.3536 11.6464C17.2598 11.5527 17.1326 11.5 17 11.5C16.8674 11.5 16.7402 11.5527 16.6464 11.6464C16.5527 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.5527 12.2598 16.6464 12.3536C16.7402 12.4473 16.8674 12.5 17 12.5Z"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Card>
						</Grid>
					</Grid>
				</HeroSection>
			)}
			{(isSmall || isMedium) && (
				<Section theme="light" ruler={false} padding="small">
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="none"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="none"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={2}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							<Caption>Get a Quote in 5 Minutes</Caption>
							<Title>
								We can help you get the lowest possible rates
							</Title>
						</Grid>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={11}
							smallColumnGap="none"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={11}
							mediumColumnGap="none"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={11}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							{products.map((product) => (
								<Card
									key={product.attributes.slug}
									text={product.attributes.hero_title}
									href={`/products/${product.attributes.slug}`}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style={{
											width: "100%",
											height: "100%",
										}}
									>
										<g clipPath="url(#clip0_1_2)">
											<path
												d={
													product.attributes
														.icon_svg_d
												}
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</g>
									</svg>
								</Card>
							))}

							<Card
								text="More Choices"
								href="https://rater.gia-usa.com/contact"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<path
										d="M7 12.5C7.13261 12.5 7.25979 12.4473 7.35355 12.3536C7.44732 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5C6.86739 11.5 6.74021 11.5527 6.64645 11.6464C6.55268 11.7402 6.5 11.8674 6.5 12C6.5 12.1326 6.55268 12.2598 6.64645 12.3536C6.74021 12.4473 6.86739 12.5 7 12.5V12.5ZM12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12C12.5 11.8674 12.4473 11.7402 12.3536 11.6464C12.2598 11.5527 12.1326 11.5 12 11.5C11.8674 11.5 11.7402 11.5527 11.6464 11.6464C11.5527 11.7402 11.5 11.8674 11.5 12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5ZM17 12.5C17.1326 12.5 17.2598 12.4473 17.3536 12.3536C17.4473 12.2598 17.5 12.1326 17.5 12C17.5 11.8674 17.4473 11.7402 17.3536 11.6464C17.2598 11.5527 17.1326 11.5 17 11.5C16.8674 11.5 16.7402 11.5527 16.6464 11.6464C16.5527 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.5527 12.2598 16.6464 12.3536C16.7402 12.4473 16.8674 12.5 17 12.5Z"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Card>
						</Grid>
					</Grid>
				</Section>
			)}
			{(isSmall || isMedium) && (
				<Section theme="white" ruler={false} padding="small">
					<Grid
						smallColumnNumber={2}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={4}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Image
							src="/nationwide-gia2.svg"
							alt="Nationwide"
							width={100}
							height={40}
						/>
						<Image
							src="/progressive-gia2.svg"
							alt="Progressive"
							width={100}
							height={12}
						/>

						<Image
							src="/mercury-gia2.svg"
							alt="Mercury Insurance"
							width={93}
							height={19}
						/>

						<Image
							src="/lemonade-gia2.svg"
							alt="Lemonade"
							width={100}
							height={23}
						/>

						<Image
							src="/hagerty-gia2.svg"
							alt="Hagerty"
							width={100}
							height={20}
						/>

						<Image
							src="/chubb-gia2.svg"
							alt="Chubb"
							width={100}
							height={12}
						/>

						<Image
							src="/travelers-gia.svg"
							alt="Travelers"
							width={100}
							height={50}
						/>

						<Image
							src="/hartford-gia2.svg"
							alt="The Hartford"
							width={100}
							height={60}
						/>
					</Grid>
				</Section>
			)}
			{isLarge && (
				<Section theme="light" ruler={false} padding="medium">
					<Grid
						smallColumnNumber={2}
						smallRowNumber={4}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={4}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Image
							src="/nationwide-gia2.svg"
							alt="Nationwide"
							width={100}
							height={40}
						/>
						<Image
							src="/progressive-gia2.svg"
							alt="Progressive"
							width={100}
							height={12}
						/>

						<Image
							src="/mercury-gia2.svg"
							alt="Mercury Insurance"
							width={93}
							height={19}
						/>

						<Image
							src="/lemonade-gia2.svg"
							alt="Lemonade"
							width={100}
							height={23}
						/>

						<Image
							src="/hagerty-gia2.svg"
							alt="Hagerty"
							width={100}
							height={20}
						/>

						<Image
							src="/chubb-gia2.svg"
							alt="Chubb"
							width={140}
							height={100}
						/>

						<Image
							src="/travelers-gia.svg"
							alt="Travelers"
							width={140}
							height={100}
						/>

						<Image
							src="/hartford-gia2.svg"
							alt="The Hartford"
							width={100}
							height={80}
						/>
					</Grid>
				</Section>
			)}
			<Section
				theme={isSmall || isMedium ? "light" : "white"}
				padding={isSmall || isMedium ? "small" : "medium"}
				ruler={false}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={1}
					largeRowNumber={2}
					largeColumnGap="none"
					largeRowGap="medium"
					largeWidth={100}
					justify="start"
					align="center"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="center"
					>
						<Caption>Better Coverage at Better Prices</Caption>
						<Title>
							Get the right coverage for less by comparing prices
							from the best insurance carriers
						</Title>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="start"
					>
						<Detail
							title="Simple and fast applications"
							body="Fill out an application in 5 minutes for
								(almost) any type of insurance, personal or
								commercial."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_9_10)">
									<path
										d="M8 10H16M8 18H16M8 14H12M4 21.4V2.6C4 2.44087 4.06321 2.28826 4.17574 2.17574C4.28826 2.06321 4.44087 2 4.6 2H16.252C16.4111 2.00014 16.5636 2.06345 16.676 2.176L19.824 5.324C19.88 5.3799 19.9243 5.44632 19.9545 5.51943C19.9847 5.59254 20.0002 5.6709 20 5.75V21.4C20 21.4788 19.9845 21.5568 19.9543 21.6296C19.9242 21.7024 19.88 21.7685 19.8243 21.8243C19.7685 21.88 19.7024 21.9242 19.6296 21.9543C19.5568 21.9845 19.4788 22 19.4 22H4.6C4.52121 22 4.44319 21.9845 4.37039 21.9543C4.29759 21.9242 4.23145 21.88 4.17574 21.8243C4.12002 21.7685 4.07583 21.7024 4.04567 21.6296C4.01552 21.5568 4 21.4788 4 21.4V21.4Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16 2V5.4C16 5.55913 16.0632 5.71174 16.1757 5.82426C16.2883 5.93679 16.4409 6 16.6 6H20"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Your insurance one-stop-shop"
							body="Review coverages from 30+ of the best insurance carriers side-by-side and in one place."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_8_9)">
									<path
										d="M8.99999 6H20M4.99999 6.01L5.00999 5.999M4.99999 12.01L5.00999 11.999M3.79999 17.8L4.59999 18.6L6.59999 16.6M8.99999 12H20M8.99999 18H20"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Insurance in a snap"
							body="Find a match, get a better rate, and save. Our licensed agents will help with whatever you need."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_8_8)">
									<path
										d="M8 12L11 15L16 10"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.821 2.487 15.53 3.338 17L2.5 21.5L7 20.662C8.51954 21.5411 10.2445 22.0027 12 22V22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="The only service you will need"
							body="Manage, switch, and update your policies in one place in a matter of seconds with just a few taps or clicks."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_7_2)">
									<path
										d="M12 16.01L12.01 15.999"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7 19.4V4.6C7 4.44087 7.06321 4.28826 7.17574 4.17574C7.28826 4.06321 7.44087 4 7.6 4H16.4C16.4788 4 16.5568 4.01552 16.6296 4.04567C16.7024 4.07583 16.7685 4.12002 16.8243 4.17574C16.88 4.23145 16.9242 4.29759 16.9543 4.37039C16.9845 4.44319 17 4.52121 17 4.6V19.4C17 19.4788 16.9845 19.5568 16.9543 19.6296C16.9242 19.7024 16.88 19.7685 16.8243 19.8243C16.7685 19.88 16.7024 19.9242 16.6296 19.9543C16.5568 19.9845 16.4788 20 16.4 20H7.6C7.44087 20 7.28826 19.9368 7.17574 19.8243C7.06321 19.7117 7 19.5591 7 19.4V19.4Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "white" : "light"}
				padding={isSmall || isMedium ? "small" : "medium"}
				ruler={false}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={2}
					largeRowNumber={1}
					largeColumnGap="none"
					largeRowGap="none"
					largeWidth={100}
					justify="start"
					align="start"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={3}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={3}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={3}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="start"
					>
						<Caption>International Insurance</Caption>
						<Title>Available nationwide and worldwide</Title>
						<Body>
							Even if you are looking for watercraft insurance in
							Europe, or commercial cross-border truckin
							insurance, we partner with the best domestic and
							international carriers to make sure you are covered
							wherever you need it.
						</Body>
					</Grid>
					<Picture
						src={IndexImage}
						alt="Family in a car enjoying time together"
					/>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "light" : "white"}
				ruler={false}
				padding={isSmall || isMedium ? "small" : "medium"}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={2}
					largeRowNumber={1}
					largeColumnGap="none"
					largeRowGap="none"
					largeWidth={100}
					justify="start"
					align="start"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={3}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={3}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={3}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="start"
					>
						<Caption>We&apos;re On Your Side</Caption>
						<Title>An agency you can trust</Title>
						<Body>
							Global Insurance has been providing insurance for
							over 30 years. As business professionals with our
							own priorities and loved ones, we understand the
							value of your time and trust. That&apos;s why we
							work hard every day to earn your trust and your
							business. If you&apos;re ready to discuss your
							insurance options, we&apos;re ready to help.
						</Body>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={2}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="start"
					>
						<Detail
							title="Online experience"
							body="Get insured, quickly and easily (and 100% spam free)"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_22)">
									<path
										d="M10.586 10.586L16.95 7.05L13.414 13.414M10.586 10.586L7.04999 16.95L13.414 13.414M10.586 10.586L13.414 13.414"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19 12H18M6 12H5M12 5V6M12 18V19M7.05 7.05L7.757 7.757M16.243 16.243L16.95 16.95M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="24/7 support"
							body="Life's complicated enough, insurance shouldn't be"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "white" : "light"}
				ruler={false}
				padding={isSmall || isMedium ? "small" : "medium"}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={1}
					largeRowNumber={2}
					largeColumnGap="none"
					largeRowGap="small"
					largeWidth={100}
					justify="start"
					align="center"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="center"
					>
						<Caption>Our Mission</Caption>
						<Title>
							Service will always be our principle objective
						</Title>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={4}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<Detail
							title="Protect"
							body="We protect the interest of all our clients in regards to premiums, claims and quality of products"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Innovate"
							body="We diligently pursue innovative products and services that our customers require"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Provide"
							body="We provide essential products and services that enhance lives and businesses"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Trust"
							body="We create a secure and trustworthy environment"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
		</Page>
	);
};

export const getStaticProps: GetStaticProps = async () => {
	try {
		const apiKey = process.env.CMS_API_KEY;

		const response = await axios.get<ProductResponse>(
			"https://cms.gia-usa.com/api/products?populate=*",
			{
				headers: {
					Authorization: `Bearer ${apiKey}`,
				},
			}
		);

		console.log(response.data);

		// Return 404 if no products found
		if (!response.data || !response.data.data.length) {
			return {
				notFound: true,
			};
		}

		return {
			props: {
				products: response.data.data,
			},
			revalidate: 60, // Revalidate at most once per minute
		};
	} catch (error) {
		console.error("Error fetching products:", error);
		return {
			notFound: true,
		};
	}
};

export default Index;
